import React, { useLayoutEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Footer from "../Component/Footer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { MetaTags } from 'react-meta-tags';
const Service = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  const truncatedTextLength = 400; // You can adjust the length based on your preference

  const TextBlock = ({ title, text }) => {
    const [showFullText, setShowFullText] = useState(false);

    const toggleReadMore = () => {
      setShowFullText(!showFullText);
    };

    const renderText = () => {
      if (showFullText) {
        return text;
      } else {
        return text.slice(0, truncatedTextLength) + (text.length > truncatedTextLength ? '...' : '');
      }
    };


    return (
      <div className="brand-block-two">
        <div className="inner-box animated">
          <div className="upper-box">
            <div className="upper-inner">{/* Image code */}</div>
          </div>
          <div className="lower-box">
            <div className="box-inner">
              <h3>{title}</h3>
              <div className="text">
                {renderText()}
                <button onClick={toggleReadMore} className="read-more-btn">
                  {showFullText ? 'Read Less' : 'Read More'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    
    <div>
       {/* ---------------------------SEO start------------------ */}
       <MetaTags>
        <title>Interior Services in Thane | Ask Interior</title>
        <meta name="title" content="Interior Services in Thane | Ask Interior" />
        <meta name="og:title" content="Interior Services in Thane | Ask Interior" />
        <meta name="description" content="Experience the epitome of luxury and functionality with our premium Interior Services in Thane. Our skilled professionals bring your vision to life." />
        <meta property="og:description" content="Experience the epitome of luxury and functionality with our premium Interior Services in Thane. Our skilled professionals bring your vision to life." />
        <meta name="keywords" content="
        Top Interior Designers in Thane,
        Interior Designers near Thane,
        Best Interior Designer in Thane,
        Residential Interior Designers in Thane,
        Commercial Interior Designers in Thane,
        Top Commercial Interior Designers at Thane,
        Showroom Interior Designer in Thane,
        Restaurant Interior Designers in Thane,
        Best Commercial Interior Designers in Thane,
        Interior Service in Thane, 
        Famous Interior Designers in Thane,
        Safety Door Designer in Thane,
        Passage Designer in Thane,
        Best Hall Interior Designer in Thane,
        T.V. Unit Designer in Thane,
        False Ceiling Designer in Thane,
        Best Dining Room Designer in Thane,
         Top Modular Kitchen Designer in Thane,
        Bedroom Interior Designer in Thane, 
        Best Wardrobe Designer in Thane,
        Bathroom Designer in Thane" />
        <link rel="canonical" href="https://askinteriordesigner.in/famous-interior-designers-thane" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Interior Services in Thane | Ask Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://askinteriordesigner.in/" />
       
        <meta property="og:image" content="https://askinteriordesigner.in/static/media/logo.ea3155c99c1ebf485c4e.png" />
      </MetaTags>
      {/* -------------------------SEO End--------------- */}
      {/* =========== Banner section ========= */}
      <div className="AboutUs-Section-1 ">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>Interior Service in Thane</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>Service</h2>
              <p className='d-none'>
                Welcome to ASK INTERIORS , an interior service in Thane, your trusted partner in bringing design dreams to life. Our comprehensive suite of interior design services is tailored to meet the diverse needs of our clients, ensuring every space tells a unique story.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ============ Section two ========== */}
      <section className="branded-section style-two mt-lg-5"
        style={{ backgroundImage: "url(images/background/18.jpg)" }}>
        <div className="container">
          <div className="auto-container">
            <div className="title-box">
              <div className="sec-title light">
                <div className="clearfix">
                  <div className="pull-left">
                    <div className="title">Branded Services</div>
                    <h2>
                      At ASK Interior We provide Excellent Services.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="lower-section">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Complete interior work."
                    text={`Your trusted partner for complete interior solutions. With a passion for precision and an eye for detail, we specialize in turning spaces into stunning reflections of your unique style. From conceptualization to execution, our dedicated team of interior designers and craftsmen are committed to delivering comprehensive solutions that go beyond aesthetics. As the top interior designer in Thane, we bring your vision to life, ensuring that every corner is a harmonious blend of form and function. Elevate your interior experience with our holistic approach to design and craftsmanship.`}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Wood work"
                    text={`
                    ASK INTERIORS, a top interior designer in Thane, where craftsmanship meets creativity in the world of woodwork. As specialists in interior design, we take pride in our dedication to bringing warmth and character to every space through bespoke woodwork solutions. From custom furniture to intricate detailing, our skilled artisans combine traditional craftsmanship with contemporary design to create timeless pieces. At ASK INTERIORS, we believe in the transformative power of wood, crafting not just furniture but stories that resonate with your lifestyle. Step into a realm where each grain tells a unique tale, and let us redefine your interior with the beauty of wood.
                    `}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Civil work"
                    text={`
                    ASK INTERIORS, where excellence in civil work converges with innovative interior design solutions. Our commitment to quality construction forms the foundation of spaces that seamlessly marry aesthetics and structural integrity. At the top interior designer in Thane, we specialize in creating robust interiors that stand the test of time. Whether it's renovations, structural enhancements, or complete overhauls, our skilled team of professionals ensures meticulous execution and attention to detail. Elevate your living and working environments with our integrated approach to civil work, where precision meets design to build the spaces of your dreams.
                    `}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Pop work"
                    text={`
                    ASK INTERIORS, where precision and creativity unite in the realm of POP work. As specialists in interior design, we take pride in our expertise in transforming spaces with exquisite POP craftsmanship. From intricate ceiling designs to bespoke wall features, our skilled artisans bring a touch of sophistication to every project. As the top interior designer in Thane , we believe in the power of POP to shape and define interiors with elegance and flair. Step into a world where every curve and contour is meticulously crafted, and let us redefine your space with the artistry of POP work.
                    `}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Plumbing work"
                    text={`
                    As a cornerstone of our comprehensive interior design services, we take pride in delivering top-notch plumbing work to ensure functionality and comfort in every space. Our skilled team of plumbing experts combines expertise with an eye for design, ensuring that form and function coalesce seamlessly. We go beyond aesthetics, addressing the vital aspects of plumbing to create spaces that are both visually stunning and practically efficient. Trust us to elevate your interiors with our commitment to excellence in plumbing within the realm of interior design.
                    `}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Furnishing work"
                    text={`
                    As a leading interior design company, we specialize in curating exceptional furnishing solutions that transcend the ordinary. Our team of dedicated designers carefully selects and crafts furniture pieces that harmonize with your style and elevate the ambiance of your spaces. We understand that furnishing is not just about functionality but also a reflection of your personality. Explore a world of curated designs and unparalleled comfort with our bespoke furnishing services, where every piece tells a story of craftsmanship and elegance.
                    `}
                  />
                </div>

                <div className="col-lg-6 col-md-6 col-12">
                  <TextBlock
                    title="Electric work"
                    text={`
                    ASK INTERIORS, where innovation meets illumination in the realm of electric work within interior design. As a cornerstone of our comprehensive design services, we pride ourselves on seamlessly integrating cutting-edge electrical solutions into your spaces. Our skilled electricians collaborate with our design team to ensure a perfect blend of functionality, safety, and aesthetics.  We illuminate your vision by combining technical expertise with a creative approach, ensuring that every switch, light, and electrical detail enhances the overall design harmony. Experience a fusion of style and utility with our commitment to excellence in electric work, shaping interiors that truly spark brilliance.                    `}
                  />
                </div>

              </div>
            </div>
          </div>
        </div >
      </section >
      <Footer />
    </div >
  )
}

export default Service