import React from 'react';
// Swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay } from 'swiper/modules';

const Carscard = ({ projectoption, categoryImage, category }) => {


    return (
        <>
            <div className='col-md-4 col-sm-6'>
                <div className='Cars-Card-Main-con'>
                    <div className='Cars-Card-con'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {
                                categoryImage.map((ele, Projectvalue) => {
                                    return (
                                        <>
                                            <SwiperSlide>
                                                <div className='Cars-Card-Image-con'>
                                                    <img src={ele.Images} alt={Projectvalue} />
                                                </div>
                                            </SwiperSlide>
                                        </>
                                    )
                                })
                            }
                        </Swiper>
                        {/* <div className='Cars-Card-Details'>
                            <h2>{projectoption}</h2>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Carscard