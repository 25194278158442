import React from 'react';
import Header from '../Component/Header';
import "../CSS/Contact.css";
import { Link } from 'react-router-dom';
import $ from "jquery";
import { BsFillPinMapFill } from 'react-icons/bs';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import Footer from '../Component/Footer';
import { MdEmail } from "react-icons/md";
import { MdCall } from "react-icons/md";
import { MetaTags } from 'react-meta-tags';

const Contact = () => {

    const [custName, setcustName] = useState("");
    const [custEmail, setcustEmail] = useState("");
    const [custPhone, setcustPhone] = useState("");
    const [custMessage, setcustMessage] = useState("");

    const submitForm = (e) => {

        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ask Interior Designer</h2><p>Hello Ask Interior Designer,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custName +
        '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custEmail +
        '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custPhone +
        '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
        custMessage +
        '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ask Interior Designer</span>.</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {

            toEmail: "info.askinterior@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Lead genration",
            mailBody: body,
            accountName: "askInterior",
            accountLeadSource: "https://askinteriordesigner.in/",
            accountLeadName: custName,
            accountLeadPhone: custPhone,
            accountLeadEmail: custEmail,
            accountLeadMsgs: custMessage
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + custName);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        e.preventDefault()

        return false;
    }



    return (
        <>
        {/* ---------------------------SEO start------------------ */}
        <MetaTags>
        <title>Famous Interior Designers in Thane | Ask Interior</title>
        <meta name="title" content="Famous Interior Designers in Thane | Ask Interior" />
        <meta name="og:title" content="Famous Interior Designers in Thane | Ask Interior" />
        <meta name="description" content="Uncover the world of renowned talent with Famous Interior Designers in Thane. Elevate your living or working spaces with iconic design concepts." />
        <meta property="og:description" content="Uncover the world of renowned talent with Famous Interior Designers in Thane. Elevate your living or working spaces with iconic design concepts." />
        <meta name="keywords" content="
        Top Interior Designers in Thane,
        Interior Designers near Thane,
        Best Interior Designer in Thane,
        Residential Interior Designers in Thane,
        Commercial Interior Designers in Thane,
        Top Commercial Interior Designers at Thane,
        Showroom Interior Designer in Thane,
        Restaurant Interior Designers in Thane,
        Best Commercial Interior Designers in Thane,
        Interior Service in Thane, 
        Famous Interior Designers in Thane,
        Safety Door Designer in Thane,
        Passage Designer in Thane,
        Best Hall Interior Designer in Thane,
        T.V. Unit Designer in Thane,
        False Ceiling Designer in Thane,
        Best Dining Room Designer in Thane,
         Top Modular Kitchen Designer in Thane,
        Bedroom Interior Designer in Thane, 
        Best Wardrobe Designer in Thane,
        Bathroom Designer in Thane" />
        <link rel="canonical" href="https://askinteriordesigner.in/famous-interior-designers-thane" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Interior Services in Thane | Ask Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://askinteriordesigner.in/" />
       
        <meta property="og:image" content="https://askinteriordesigner.in/static/media/logo.ea3155c99c1ebf485c4e.png" />
      </MetaTags>
      {/* -------------------------SEO End--------------- */}
            <div className='contact-Section-1'>
                {/* <Header /> */}
                <div className='contact-Section-Banner'>
                    <div className='contact-banner-text'>
                        <h1>Famous Interior Designers in Thane</h1>
                        <div className='contact-banner-text-page'>
                            <Link to="/">
                                Home
                            </Link>
                            <h2>
                                /
                            </h2>
                            <p className='d-none'>Thank you for considering ASK INTERIORS for your interior design needs. As we are famous interior designer in Thane, we are excited to connect with you and turn your vision into reality. Feel free to reach out through any of the following channels :</p>
                            <h2>
                                Contact
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------ Section 2 ------------------------------ */}
            <section className='Contact-Section-2'>
                <div className='container'>
                    <div className='Contact-Section-Contact-Details-Con'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 mt-lg-0 mt-3'>
                                <div className='Contact-Section-Card'>
                                    <div className='Contact-Section-Card-icon-con'>
                                        <div className='Contact-Section-Card-icon'>
                                            <BsFillPinMapFill />
                                        </div>
                                    </div>
                                    <div className='Contact-Section-Card-Details'>
                                        <h3>Address</h3>
                                        <p><a href=''> Gala No: 01, Masan wada, Castle Mill Flyover, Narendra Ballal Marg, near Shankar Mandir, Azad Nagar, Thane, Maharashtra 400612</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 mt-lg-0 mt-3'>
                                <div className='Contact-Section-Card'>
                                    <div className='Contact-Section-Card-icon-con'>
                                        <div className='Contact-Section-Card-icon'>
                                        <MdCall />
                                        </div>
                                    </div>
                                    <div className='Contact-Section-Card-Details'>
                                        <h3>Contact Number</h3>
                                        <p><a href='tel:+91 7506308899'>Mobile :- +91 7506308899</a></p>
                                        {/* <p><a href='tel:+91 93726 98322'>Office :- +91 93726 98322</a></p> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 mt-lg-0 mt-3'>
                                <div className='Contact-Section-Card'>
                                    <div className='Contact-Section-Card-icon-con'>
                                        <div className='Contact-Section-Card-icon'>
                                        <MdEmail />
                                        </div>
                                    </div>
                                    <div className='Contact-Section-Card-Details'>
                                        <h3>Email Addresss</h3>
                                        <p><a href="mailto:info.askinterior@gmail.com">info.askinterior@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ============= section Four ======= */}
            <section className='ContactUs-form-Section-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-5 col-sm-12'>
                            <div className='contact-form-image'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d120610.05704514809!2d72.82175578216169!3d19.1486632387835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bf44aaa556c1%3A0xe55c7fd448492f31!2sAsk%20Interior%20Design!5e0!3m2!1sen!2sin!4v1702365821096!5m2!1sen!2sin" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div className='col-md-7 col-sm-12'>
                            <div className='Name-input'>
                                <div className="row">
                                    <div className="name">
                                        <input type="text" className="form-control custName" onChange={(e) => setcustName(e.target.value)} placeholder="First name" />
                                    </div>
                                </div>
                            </div>

                            <div className='Email'>
                                <input type="email" className="form-control custEmail" onChange={(e) => setcustEmail(e.target.value)} placeholder="Email" />
                            </div>

                            <div className='Phone-no'>
                                <input type="number" className="form-control custPhone" onChange={(e) => setcustPhone(e.target.value)} placeholder="contact no" />
                            </div>
                            <div className='message'>
                                <textarea type="text" className="form-control custMessage" onChange={(e) => setcustMessage(e.target.value)} placeholder='message' />
                            </div>
                            <button className='form-submit-button' onClick={submitForm} >Submit</button>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}

export default Contact