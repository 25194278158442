import React, { useLayoutEffect } from "react";

import "../CSS/AboutUs.css";
import { Link } from "react-router-dom";


import Footer from "../Component/Footer";
import quote from "../Img/quote.svg";


//////////////// Why Unico Icons //////////////////

import BestPrice from "../Img/Icons/BestPrice.png";
import HappyCustomers from "../Img/Icons/HappyCustomers.png";
import ExtraStorage from "../Img/Icons/ExtraStorage.png";
import Warranty from "../Img/Icons/Warranty.png";
import PersonalisedDesigns from "../Img/Icons/PersonalisedDesigns.png";
import OnTime from "../Img/Icons/OnTime.png";

// ========= About Img =========
import About2 from "../Img/gallery/img14.jpg";
import About1 from "../Img/Work Images/bedroom wall design.webp";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { MetaTags } from 'react-meta-tags';

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const AboutUs = () => {
  
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <>
      <MetaTags>
        <title>Interior Designers near Thane | Ask Interior</title>
        <meta name="title" content="Interior Designers near Thane | Ask Interior" />
        <meta name="og:title" content="Interior Designers near Thane | Ask Interior" />
        <meta name="description" content="Experience a new era of business aesthetics with the Top Commercial Interior Designers at Thane. Elevate your workspace with innovative design solutions." />
        <meta property="og:description" content="Experience the epitome of luxury and functionality with our premium Interior Services in Thane. Our skilled professionals bring your vision to life." />
        <meta name="keywords" content="
        Top Interior Designers in Thane,
        Interior Designers near Thane,
        Best Interior Designer in Thane,
        Residential Interior Designers in Thane,
        Commercial Interior Designers in Thane,
        Top Commercial Interior Designers at Thane,
        Showroom Interior Designer in Thane,
        Restaurant Interior Designers in Thane,
        Best Commercial Interior Designers in Thane,
        Interior Service in Thane, 
        Famous Interior Designers in Thane,
        Safety Door Designer in Thane,
        Passage Designer in Thane,
        Best Hall Interior Designer in Thane,
        T.V. Unit Designer in Thane,
        False Ceiling Designer in Thane,
        Best Dining Room Designer in Thane,
         Top Modular Kitchen Designer in Thane,
        Bedroom Interior Designer in Thane, 
        Best Wardrobe Designer in Thane,
        Bathroom Designer in Thane" />
        <link rel="canonical" href="https://askinteriordesigner.in/interior-services-thane" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://askinteriordesigner.in/" />
       
        <meta property="og:image" content="https://askinteriordesigner.in/static/media/logo.ea3155c99c1ebf485c4e.png" />
      </MetaTags>

      {/* =========== Banner section ========= */}
      <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>Interior Designers near Thane</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>About</h2>
            </div>
          </div>
        </div>
      </div>
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font testi-text">Ask Interior</h6>
                <h4 className="playfont testi-text">About Ask Interior</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="aboutImg col-xl-6 col-lg-6">
              <div className="home-about">
                <div className="img1">
                  <img src={About2} alt="Top Interior Designers in Thane" />
                </div>
                <div className="img2">
                  <img src={About1} alt="Interior Designers near Thane" />
                </div>
              </div>
            </div>
            <div className="content-column col-lg-6">
              <div className="inner-column">
                <div className="about-txt">
                  <span className="sub-title">Transforming Spaces, Creating Dreams: Your Vision, Our Design.</span>
                  <h2>Elevate Living through Inspired Design: Where Every Detail Matters.</h2>
                  <p>
                    Welcome to ASK INTERIORS, and meet our interior designer near Thane, where we redefine spaces with a touch of elegance and innovation. As seasoned interior designers , we embark on a journey to transform your dreams into captivating realities.
                  </p>
                  <p className="mt-3">
                    Our commitment lies in creating bespoke designs that not only reflect your individuality but also enhance the functionality of every space. At ASK INTERIORS, our interior designer near Thane believes in the power of design to elevate the way you live, work, and experience your surroundings. Step into a world of curated aesthetics and unparalleled craftsmanship with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Section Four ========== */}
      <section className="Home-Section-2">
        <div className="container">
          <div className="HomeSection2-Whyus-text">
            <div className="HomeSection2-Whyus-maintext">
              <h2>Why</h2>
            </div>
            <div className="HomeSection2-Whyus-subtext-con">
              <div className="HomeSection2-Whyus-subtext">
                <h3>Ask Interior</h3>
                <h4>Associate</h4>
              </div>
              <div className="HomeSection2-Whyus-subtext-Q">
                <h2>?</h2>
              </div>
            </div>
          </div>

          <div className="Home-Section-WhyUS-Icon-Con">
            <div className="row row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-2 demo">
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={BestPrice} alt="Modern design" />
                    </div>
                  </div>
                  <h6>
                    <strong>Best Price</strong> Guarantee
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={HappyCustomers} alt="Luxury interiors" />
                    </div>
                  </div>
                  <h6>
                    <strong>200+</strong> happy customer
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={ExtraStorage} alt="Minimalist interiors" />
                    </div>
                  </div>
                  <h6>
                    <strong>20% Extra</strong> Storage
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={Warranty} alt="Best Interior Designer in Thane" />
                    </div>
                  </div>
                  <h6>
                    Up to <strong>10-Years</strong> of material warranty
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={PersonalisedDesigns} alt="Residential Interior Designers in Thane" />
                    </div>
                  </div>
                  <h6>
                    <strong>Personalised</strong> Designs
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={OnTime} alt="Commercial Interior Designers in Thane" />
                    </div>
                  </div>
                  <h6>
                    <strong>On-Time Delivery</strong>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Section5 ========= */}
      <section className="testimonials grid section-padding">
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font">Testimonials</h6>
                <h4 className="playfont testi-text">What People Says?</h4>
              </div>
            </div>

            <div className="Home-Section-5-Testimonial">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="item slick-slide">
                    <span className="quote-icon">
                      <img src={quote} alt="Top Commercial Interior Designers at Thane" />
                    </span>
                    <div className="cont">
                      <p className="playfont">
                        They are professional punctual. They do their work with lots of effort, answer every question with politeness excellent Knowledge of interior design if you people are looking for  interior designer  I would suggest you to take them as 1st priority                      </p>
                    </div>
                    <div className="info">
                      <h6>
                        pratiksha Shetty
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item wow fadeInUp slick-slide">
                    <span className="quote-icon">
                      <img src={quote} alt="Showroom Interior Designer in Thane" />
                    </span>
                    <div className="cont">
                      <p className="playfont">
                        Had an amazing experience with my interior designer. Their creativity, professionalism, and attention to detail were outstanding. They listened to my ideas and brought them to life while also suggesting innovative concepts. The end result was a stunning and personalized space that I absolutely love. I highly recommend their services! THANKS Ask interior contractor team 👍
                      </p>
                    </div>
                    <div className="info">
                      <h6>
                        Ayush Choubey
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="item wow slick-slide">
                    <span className="quote-icon">
                      <img src={quote} alt="Restaurant Interior Designers in Thane" />
                    </span>
                    <div className="cont">
                      <p className="playfont">
                        Team Ask interior is very Professional, Systematic and all the members are learned and experienced, so they offered us a one stop solution from design to implementation and also offered  the entire services in good price. Delivered on time and were very patient and responsive throughout the project duration.
                      </p>
                    </div>
                    <div className="info">
                      <h6>
                        Anil Raj
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="item wow fadeInUp slick-slide"
                    data-wow-delay=".3s"
                    data-slick-index={3}
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                      width: 515,
                    }}
                    aria-hidden="true"
                    tabIndex={-1}
                  >
                    <span className="quote-icon">
                      <img src={quote} alt="Best Commercial Interior Designers in Thane" />
                    </span>
                    <div className="cont">
                      <p className="playfont">
                        The team at this interior design company demonstrated an exceptional understanding of my vision and translated it into a space that exceeded my expectations. They carefully listened to my ideas and preferences, seamlessly incorporating them into a cohesive and stylish design. Their ability to blend functionality with aesthetics resulted in a space that perfectly suits my lifestyle while reflecting my personal taste.
                      </p>
                    </div>
                    <div className="info">
                      <h6>
                        Rahul Dubey
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="item wow fadeInUp slick-slide"
                    data-wow-delay=".3s"
                    data-slick-index={3}
                    style={{
                      visibility: "visible",
                      animationDelay: "0.3s",
                      animationName: "fadeInUp",
                      width: 515,
                    }}
                    aria-hidden="true"
                    tabIndex={-1}
                  >
                    <span className="quote-icon">
                      <img src={quote} alt="Interior Service in Thane " />
                    </span>
                    <div className="cont">
                      <p className="playfont">
                        Honestly one of the best interior designing company. Easy to reach, accessible and great hospitality.
                      </p>
                    </div>
                    <div className="info">
                      <h6>
                        lovkush rajbhar
                      </h6>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
