import React, { useState } from 'react'
import $ from "jquery";
import "../Contact/HomeContact.css";
const HomeContact = () => {

    const [errors, setErrors] = useState({
        custName: "",
        custEmail: "",
        custContact: "",
        custMessage: "",
    });

    const handleValidation = () => {
        let isValid = true;
        const newErrors = {
            custName: "",
            custEmail: "",
            custContact: "",
            custWork: "",
            custMessage: "",
        };
        if (!custName.trim()) {
            isValid = false;
            newErrors.custName = "Name is required";
        }

        const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        if (!custEmail.match(emailRegex)) {
            isValid = false;
            newErrors.custEmail = "Email is not valid";
        }

        if (!custContact.trim()) {
            isValid = false;
            newErrors.custContact = "Phone is required";
        } else if (!/^\d{10}$/.test(custContact)) {
            isValid = false;
            newErrors.custContact = "Phone must have 10 digits";
        }
        if (!custWork.trim()) {
            isValid = false;
            newErrors.custWork = "Enter Total People";
        }

        if (!custMessage.trim()) {
            isValid = false;
            newErrors.custMessage = "Write a Message";
        }

        setErrors(newErrors);
        return isValid;
    };
    // ============ Mail function ==========
    const [custName, setCustName] = useState("");
    const [custEmail, setCustEmail] = useState("");
    const [custContact, setCustContact] = useState("");
    const [custWork, setCustWork] = useState("");
    const [custMessage, setCustMessage] = useState("");
    // ============== Mail Print ======
    const ServForm = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            var body =
                '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#6e3b70">Ask Interior Designer</h2><p>Hello Ask Interior Designer,</p><p>Thank you for your interest in our services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left;color:#6e3b70">' +
                custName +
                '</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left;color:#6e3b70">' +
                custEmail +
                '</td></tr><tr><td style="text-align:left"><strong>Phone:</strong></td><td style="text-align:left;color:#6e3b70">' +
                custContact +
                '</td></tr><tr><td style="text-align:left"><strong>Total People:</strong></td><td style="text-align:left;color:#6e3b70">' +
                custWork +
                '</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left;color:#6e3b70">' +
                custMessage +
                '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at<span style="text-align:left;color:#6e3b70;padding-left:5px">Ask Interior Designer</span>.</p></div></body></html>';
            $.post(
                "https://skdm.in/server/v1/send_lead_mail.php",
                {
                    toEmail: "info.askinterior@gmail.com",
                    fromEmail: "skdmlead@gmail.com",
                    bccMail: "skdmlead@gmail.com",
                    mailSubject: "New Customer Lead",
                    mailBody: body,
                    accountName: "askInterior",
                    accountLeadSource: "https://askinteriordesigner.in/",
                    accountLeadName: custName,
                    // accountLeadEmail: custEmail,
                },
                function (dataa, status) {
                    console.log("data :" + dataa);
                    console.log("name:" + custName);
                    console.log("name:" + custEmail);
                }
            );

            alert("Your Form has Submitted Our team will contact with You  soon.");
            e.preventDefault();
            return false;
        }
    };

    return (
        <>
            {/* =========== Form  Section ========== */}
            <section className="booking-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 booking-column">
                            <div className="booking-left">
                                <div className="booking-left-Txt">
                                    <span>Stay With Us</span>
                                    <h2>Contact Detail</h2>
                                </div>
                                <div className="booking-form">
                                    <div className="row">
                                        <div class="col-sm-6 mb-2">
                                            <div class="form-group">
                                                <label for="name">Your Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={custName}
                                                    onChange={(e) => setCustName(e.target.value)}
                                                />
                                                {errors.custName && (
                                                    <span className="error-text">{errors.custName}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <div class="form-group">
                                                <label for="name">Your Email</label>
                                                <input
                                                    class="form-control"
                                                    type="text"
                                                    name="email"
                                                    value={custEmail}
                                                    onChange={(e) => setCustEmail(e.target.value)}
                                                />
                                                {errors.custEmail && (
                                                    <span className="error-text">{errors.custEmail}</span>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <div class="form-group">
                                                <label for="name">Phone</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="contact"
                                                    value={custContact}
                                                    onChange={(e) => setCustContact(e.target.value)}
                                                />
                                                {errors.custContact && (
                                                    <span className="error-text">
                                                        {errors.custContact}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <div class="form-group">
                                                <label for="name">Work</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="Work"
                                                    value={custWork}
                                                    onChange={(e) => setCustWork(e.target.value)}
                                                />
                                                {errors.custContact && (
                                                    <span className="error-text">
                                                        {errors.custWork}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div class="form-group">
                                                <label for="name">Message</label>
                                                <textarea
                                                    class="form-control"
                                                    name="message custMessage"
                                                    id="custMessage1"
                                                    value={custMessage}
                                                    rows={6}
                                                    onChange={(e) => setCustMessage(e.target.value)}
                                                ></textarea>
                                                {errors.custMessage && (
                                                    <span className="error-text">
                                                        {errors.custMessage}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div class="form-btn">
                                                <button className="booking-btn" onClick={ServForm}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 booking-content">
                            <div className="booking-right">
                                <div className="bookingsec-title">
                                    <span className="sub-title">Ask Interior</span>
                                    <h2>Contact Us</h2>
                                </div>
                                <h4>Address</h4>
                                <p>
                                    Gala No: 01, Masan wada, Castle Mill Flyover, Narendra Ballal Marg, near Shankar Mandir, Azad Nagar, Thane, Maharashtra 400612
                                </p>
                                <h4 className="mt-3">Contact</h4>
                                <p>
                                    <a href="tel:+91 7506308899">+91 7506308899</a>
                                    <br />
                                    <a href="tel:+91  93726 98322">+91  93726 98322</a>
                                </p>

                                <h4 className="mt-3">Mail</h4>
                                <p>
                                    <a href="mailto: info.askinterior@gmail.com">
                                    info.askinterior@gmail.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeContact