import logo from './logo.svg';
import './App.css';
import Errorpage from './Pages/Errorpage';
  import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import HomePage from './Pages/HomePage';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import Projectdata from './Component/Projectdata';
import Project from './Pages/Project';
import Service from './Pages/Service';
import Career from './Pages/Career';
import Header from "./Component/Header";
// import Magazindata from './Component/Magazindata';
import ProjectInfo from './Pages/ProjectInfo';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/interior-designers-near-thane' element={<AboutUs />} />
          <Route path='/famous-interior-designers-thane' element={<Contact />} />
          {/* <Route path='/Project' element={<Project />} /> */}
          <Route path='/interior-services-thane' element={<Service />} />
          <Route path='/Career' element={<Career />} />
          <Route path='/*' element={<Errorpage/>} />
          {
            Projectdata.map((route, index) => {
              return (
                <>
                  <Route
                    key={index}
                    exact
                    path={route.ProjectSlug}
                    element={<ProjectInfo />}
                  />
                </>
              )
            })
          }
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
