import React, { useState } from 'react';
import "../CSS/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsWhatsapp } from 'react-icons/bs';
import { MdCall } from "react-icons/md";
import Logo from "../Img/logo.png";
const Header = () => {


    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)


    window.addEventListener("scroll", changeColor);
    // popup Modal
    const [modalShow, setModalShow] = React.useState(false);

    const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <>
            <div className="nav-bar-component">
                <div className="NavSection">


                    <Navbar collapseOnSelect expand="lg" variant="dark">
                        <Container>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
                            <Navbar.Brand>
                                <Link to="/">
                                    <div className='NavLogo'>
                                        <img src={Logo} alt="best interior designer thane" />
                                    </div>
                                </Link>
                            </Navbar.Brand>
                            <Navbar.Collapse id="responsive-navbar-nav" className="main_nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                                    <Nav.Link href="/interior-designers-near-thane"><Link to="/interior-designers-near-thane">About</Link></Nav.Link>
                                    {/* <Nav.Link href="#About us"><Link to="/Project">Project</Link></Nav.Link> */}
                                    <button className="dropdownShow">
                                        Project
                                        <div className="dorpdown">
                                            <div className="dropdown_list">
                                                <Nav.Link href="/safety-door-designer-thane">
                                                    <Link to="/safety-door-designer-thane">Door</Link>
                                                </Nav.Link>
                                                {/* <Nav.Link href="/Passage">
                                                    <Link to="/Passage">Passage</Link>
                                                </Nav.Link> */}
                                                <Nav.Link href="/best-hall-interior-designer-thane">
                                                    <Link to="/best-hall-interior-designer-thane">Hall</Link>
                                                </Nav.Link>
                                                <Nav.Link href="/tv-unit-designer-thane">
                                                    <Link to="/tv-unit-designer-thane">Tv Unit</Link>
                                                </Nav.Link>
                                                <Nav.Link href="/false-ceiling-designer-thane">
                                                    <Link to="/false-ceiling-designer-thane">False Ceiling</Link>
                                                </Nav.Link>
                                                <Nav.Link href="/best-dining-room-designer-thane">
                                                    <Link to="/best-dining-room-designer-thane">Dining Room</Link>
                                                </Nav.Link>
                                                <Nav.Link href="/top-modular-kitchen-designer-thane">
                                                    <Link to="/top-modular-kitchen-designer-thane">Kitchen</Link>
                                                </Nav.Link>
                                                <Nav.Link href="/bedroom-interior-designer-thane">
                                                    <Link to="/bedroom-interior-designer-thane">BedRoom</Link>
                                                </Nav.Link>
                                              
                                            </div>
                                        </div>
                                    </button>
                                    <Nav.Link href="/interior-services-thane"><Link to="/interior-services-thane">Service</Link></Nav.Link>
                                    <Nav.Link href="/famous-interior-designers-thane"><Link to="/famous-interior-designers-thane">Contact</Link></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="Social-media-icons">
                                <Nav.Link href='https://www.instagram.com/ask.interiordesigns/' target="_blank"><AiFillInstagram /></Nav.Link>
                                <Nav.Link href='https://www.facebook.com/askinteriordesigners/' target="_blank"><FaFacebookSquare /></Nav.Link>
                                <Nav.Link href="tel:+91 82914 73825"><MdCall /></Nav.Link>
                                <Nav.Link href='https://wa.me/+918291473825?text=' target="_blank"><BsWhatsapp /></Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default Header