import React from "react";
import { Link } from "react-router-dom";
import Header from "../Component/Header";
import "../CSS/Career.css";
import CareerImage from "../Img/gallery/img12.jpg";
import Footer from "../Component/Footer";
const Career = () => {
  return (
    <>
      <div className="career-Section-1">
        {/* <Header /> */}
        <div className="career-Section-Banner">
          <div className="career-banner-text">
            <h1>Career</h1>
            <div className="career-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>Career</h2>
            </div>
          </div>
        </div>
      </div>
      {/* ============== Section 2 ========== */}
      <section className="About-Section6">
        <div className="container">
          <div className="About-Section6-Heading">
            <h2>CAREER WITH US</h2>
          </div>
          <div className="AboutUs-Founder">
            <div className="row">
              <div className="col-md-6">
                <div className="AboutUs-Founder-Image-con">
                  <div className="AboutUs-Founder-Image">
                    <img src={CareerImage} alt="Skin Care Doctor" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Founder-Details">
                  <div className="Founder-Info">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Omnis, magni dicta numquam exercitationem adipisci sint
                      accusamus perferendis blanditiis accusantium quos alias
                      debitis iste illum maiores vitae a ullam nesciunt placeat
                      repellendus, ratione nobis. Eligendi quo atque
                      reprehenderit, quos mollitia inventore dicta optio,
                      dolorum impedit fugiat dolore ullam? Ut, officia velit?
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Eaque non dolore doloremque! At qui dignissimos, et omnis
                      impedit id voluptates voluptatibus atque! Cupiditate nihil
                      tenetur et voluptatibus nam deleniti necessitatibus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =============== Section 3 =========== */}
      <section className="project-form-section">
        <div className="auto-container">
          <div className="row clearfix">
            {/* Content Column */}
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                {/* Sec Title */}
                <div className="sec-title">
                  <div className="inner-title">
                    {/* <div className="title">We Are Hiring</div> */}
                    <h2>We Are Hiring</h2>
                    <div className="text">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Ab veniam eum natus maiores laborum dicta, perspiciatis
                      quibusdam cupiditate nisi aut, ut commodi accusamus nihil
                      numquam vel aspernatur, nobis sed! Non?
                    </div>
                    <div className="Hiring-con">
                      <h3>What we're looking for:</h3>
                      <p>
                        1. Lorem ipsum, dolor sit amet consectetur adipisicing.
                      </p>
                      <p>2. Lorem ipsum dolor sit amet.</p>
                      <p>
                        3. Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit.
                      </p>
                      <p>
                        4. Lorem ipsum dolor sit amet consectetur adipisicing.
                      </p>
                      <p>
                        5. Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Sequi.
                      </p>
                    </div>
                    {/* <div className='Hiring-con'>
                                            <h3>Why work with us:</h3>
                                            <p>Exciting and diverse projects that challenge your creativity</p>
                                            <p>Collaborative and supportive team environment</p>
                                            <p>Opportunities for professional growth and development</p>
                                            <p>Competitive compensation and benefits package</p>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Form Column */}
            <div className="form-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                {/* Project Form */}
                <div className="project-form">
                  {/*Project Form*/}
                  <form method="post">
                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        placeholder="Name"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Subject"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div className="form-group text-center">
                      <button
                        className="theme-btn btn-style-one"
                        type="submit"
                        name="submit-form"
                      >
                        send message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============ Section3 ============ */}
      <section className="Career-Section-3">
        <div className="container">
          <div className="About-Section6-Heading">
            <h2>GET DIRECTIONS</h2>
          </div>
          <div className="Career-Section3-map">
            <iframe
              src="https://www.google.com/maps"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Career;
