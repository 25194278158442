import React from 'react'
import "../CSS/Footer.css";
import { Link } from "react-router-dom";
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsWhatsapp } from 'react-icons/bs';
import { MdCall } from "react-icons/md";
const Footer = () => {
  return (
    <div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-lg-5 col-md-6  mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" />
                  <div className="cta-text pt-2">
                    <h4>Find us</h4>
                    <span>
                      Gala No: 01, Masan wada, Castle Mill Flyover, Narendra Ballal Marg, near Shankar Mandir, Azad Nagar, Thane, Maharashtra 400612</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text pt-2">
                    <h4>Call us</h4>
                    <span><a href="tel:+91 82914 73825">+91 82914 73825</a>/ <a href="tel:+91  93726 98322">+91  93726 98322</a></span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" />
                  <div className="cta-text pt-2">
                    <h4>Mail us</h4>
                    <span>
                      <a href="mailto: info.askinterior@gmail.com">
                      info.askinterior@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  {/* <div className="footer-logo">
                    <a href="index.html">
                    </a>
                  </div> */}
                  <div className="footer-text">
                    <p>
                      Ask Interior Design is a Micro enterprise engaged in the Architectural And Engineering Activities And Related Technical Consultancy in Thane, Maharashtra.
                    </p>
                  </div>
                  <div className="footer-widget">
                  </div>

                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul className="m-0 p-0">
                    <li>
                      <Link to="/">Home</Link>
                    </li>



                    <li>
                      <Link to="/interior-designers-near-thane">About Us</Link>
                    </li>


                    <li>
                      <Link to="/interior-services-thane">Services</Link>
                    </li>
                    <li>
                      <Link to="/famous-interior-designers-thane">Contact Us</Link>
                    </li>

                  </ul>
                </div>

              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Social Media</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <a href='https://www.instagram.com/ask.interiordesigns/' target="_blank"><AiFillInstagram /></a>
                    <a href='https://www.facebook.com/askinteriordesigners/' target="_blank"><FaFacebookSquare /></a>
                    <a href="tel:+91 82914 73825"><MdCall /></a>
                    <a href='https://wa.me/+918291473825?text=' target="_blank"><BsWhatsapp /></a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved{" "}
                    <br />
                    <a href="https://askinteriordesigner.in/">ASK Interior Designer</a>
                  </p>
                  <p>
                    Developed By &nbsp;
                    <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>


    </div>
  )
}

export default Footer