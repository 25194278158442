
// Safety Door
import SafetyDoorMain from "../Img/category images/safety door 1.webp"
import SafetyDoor01 from "../Img/Work Images/safety door 1.webp";
import SafetyDoor02 from "../Img/Work Images/safety door 2.webp";
import SafetyDoor03 from "../Img/Work Images/safety door 3.webp";
import SafetyDoor04 from "../Img/Work Images/safety door 4.webp";
import SafetyDoor05 from "../Img/Work Images/safety door 5.webp";

// Passage
import PassageMain from "../Img/category images/passage 1.webp"
import Passage01 from "../Img/Work Images/passage 1.webp";
import Passage02 from "../Img/Work Images/passage 2.webp";

// Hall 
import HallMain from "../Img/category images/Hall Design 5.webp"
import Hall01 from "../Img/Work Images/Hall Design 1.webp";
import Hall02 from "../Img/Work Images/Hall Design 2.webp";
import Hall03 from "../Img/Work Images/Hall Design 3.webp";
import Hall04 from "../Img/Work Images/Hall Design 5.webp";
import Hall05 from "../Img/Work Images/hall teapoy 1.webp";

// Tv Unit
import TvUnitMain from "../Img/category images/tv unit 2.webp";
import TvUnit01 from "../Img/Work Images/tv unit 1.webp";
import TvUnit02 from "../Img/Work Images/tv unit 2.webp";
import TvUnit03 from "../Img/Work Images/tv unit 3.webp";

// False Ceiling
import FalseCeilingMain from "../Img/category images/false ceiling 1.webp";
import FalseCeiling01 from "../Img/Work Images/false ceiling 1.webp";
import FalseCeiling02 from "../Img/Work Images/false ceiling 2.webp";

// Dining Room
import diningroomMain from "../Img/category images/dining room.webp";
import diningroom01 from "../Img/Work Images/dining room.webp";

// kitchen01
import kitchenMain from "../Img/category images/kitchen 4.webp";
import kitchen01 from "../Img/Work Images/kitchen 1.webp";
import kitchen02 from "../Img/Work Images/kitchen 2.webp";
import kitchen03 from "../Img/Work Images/kitchen 3.webp";
import kitchen04 from "../Img/Work Images/kitchen 4.webp";
import kitchen05 from "../Img/Work Images/kitchen 5.webp";

// BedRoom
import BedRoomMain from "../Img/category images/bedroom 05.webp";
import BedRoom01 from "../Img/Work Images/bedroom 01.webp";
import BedRoom02 from "../Img/Work Images/bedroom 02.webp";
import BedRoom03 from "../Img/Work Images/bedroom 03.webp";
import BedRoom04 from "../Img/Work Images/bedroom 04.webp";
import BedRoom05 from "../Img/Work Images/bedroom 05.webp";
import BedRoom06 from "../Img/Work Images/bedroom 06.webp";


import Wardrobe1 from "../Img/Work Images/Wardrobe 1.webp";
import Wardrobe2 from "../Img/Work Images/Wardrobe 2.webp";
import Wardrobe3 from "../Img/Work Images/Wardrobe 3.webp";
import Wardrobe4 from "../Img/Work Images/Wardrobe 4.webp";
import Wardrobe5 from "../Img/Work Images/Wardrobe 5.webp";

// Bath Room

import BathRoom01 from "../Img/Work Images/bathroom 1.webp";
import BathRoom02 from "../Img/Work Images/bathroom 2.webp";
import BathRoom03 from "../Img/Work Images/bathroom 3.webp";
import BathRoom04 from "../Img/Work Images/bathroom 4.webp";

const Projectdata = [
    {
        id: 1,
        ProjectImg: SafetyDoorMain,
     
        ProjectName: "Safety Door Designer in Thane",
        ProjectName1: "Door",
        ProjectSlug: "/safety-door-designer-thane",
        MetaTitle: "Safety Door Designer in Thane | Ask Interior",
        MetaDes:  "Discover top-notch Safety Door Designer in Thane crafted for style and security. Our expert safety door designer ensures the protection of your home.",
        CanonicalTag: "https://askinteriordesigner.in/safety-door-designer-thane",
  
        ProejctDes: "As a residential interior designer in Thane, we specialize in crafting personalized spaces that reflect your style and elevate your living experience. Our dedicated team of experienced designers is committed to transforming your vision into a harmonious blend of aesthetics and practicality. From concept to completion, we are here to turn your home into a true expression of your lifestyle. Let's create spaces that tell your unique story.",
        ProjectSelect: [
            {

                projectoption: "Safety Door",
                Projectvalue: "Safety Door Designer in Thane",
                category: "Door",
                categoryImage: [
                    {
                        Images: SafetyDoor01,
                    },
                ]
            },
            {
                projectoption: "Safety Door",
                category: "Door",
                Projectvalue: "Safety Door Designer in Thane",
                categoryImage: [
                    {
                        Images: SafetyDoor02,
                    },
                ]
            },
            {
                projectoption: "Door",
                category: "Door",
                Projectvalue: "Safety Door Designer in Thane",
                categoryImage: [
                    {
                        Images: SafetyDoor03,
                    },
                ]
            },
            {
                projectoption: "Door",
                category: "Door",
                Projectvalue: "Safety Door Designer in Thane",
                categoryImage: [
                    {
                        Images: SafetyDoor04,
                    },
                ]
            },
            {
                projectoption: "Door",
                category: "Door",
                Projectvalue: "Safety Door Designer in Thane",
                categoryImage: [
                    {
                        Images: SafetyDoor05,
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        ProjectImg: PassageMain,
        Projectvalue: "Passage Designer in Thane",
        ProjectName: "Passage Designer in Thane",
        ProjectName1: "Passage",
        category: "Passage",
        ProjectSlug: "/passage-designer-thane",
        MetaTitle: " Passage Designer in Thane | Ask Interior",
        MetaDes:  "Enhance the flow and aesthetics of your space with a Passage Designer in Thane. Experience seamless transitions between rooms through thoughtful design.",
        CanonicalTag: "https://askinteriordesigner.in/passage-designer-thane",
  
        ProejctDes: "At ASK INTERIORS, a commercial interior designer in Thane, our seasoned team understands the dynamic needs of modern offices, offering tailor-made solutions that balance functionality and style. From ergonomic layouts to cutting-edge designs, we specialize in crafting workspaces that reflect your company's ethos and foster a culture of success. Elevate your office experience with us, where design meets productivity seamlessly.",
        ProjectSelect: [
            {
                projectoption: "Passage",
                category: "Passage",
                categoryImage: [
                    {
                        Images: Passage01,
                    },
                ],

            },
            {
                projectoption: "Passage",
                category: "Passage",
                categoryImage: [
                    {
                        Images: Passage02,
                    },
                ],

            },
        ]
    },
    {
        id: 3,
        ProjectImg: HallMain,
        Projectvalue: "Best Hall Interior Designer in Thane",
        ProjectName: "Best Hall Interior Designer in Thane",
        ProjectName1: "Hall",
        ProjectSlug: "/best-hall-interior-designer-thane",
        MetaTitle: "Safety Door Designer in Thane | Ask Interior",
        MetaDes:  "Experience the epitome of sophistication with the Best Hall Interior Designer in Thane. Transform your space into a captivating haven with our expert touch.",
        CanonicalTag: "https://askinteriordesigner.in/best-hall-interior-designer-thane",
        ProejctDes: "As your top commercial interior designer in Thane We understand the unique requirements of healthcare settings, blending aesthetic appeal with practical considerations. From calming waiting areas to efficient treatment spaces, we design clinics that prioritize both patient experience and operational efficiency. Trust us to create healing environments that reflect the care and professionalism of your clinic.",
        ProjectSelect: [
            {
                category: "Hall",
                projectoption: "Hall",
                categoryImage: [
                    {
                        Images: Hall01,
                    },
                ],
            },
            {
                projectoption: "Hall",
                category: "Hall",
                categoryImage: [
                    {
                        Images: Hall02,
                    },
                ],
            },
            {
                projectoption: "Hall",
                category: "Hall",
                categoryImage: [
                    {
                        Images: Hall03,
                    },
                ],
            },
            {
                projectoption: "Hall",
                category: "Hall",
                categoryImage: [
                    {
                        Images: Hall04,
                    },
                ],
            },
            {
                projectoption: "Hall Teapoy",
                category: "Hall",
                categoryImage: [
                    {
                        Images: Hall05,
                    },
                ],
            },
        ]
    },
    {
        id: 4,
        ProjectImg: TvUnitMain,
        Projectvalue: "T.V. Unit Designer in Thane",
        ProjectName: "T.V. Unit Designer in Thane",
        ProjectName1: "Tv Unit",
        ProjectSlug: "/tv-unit-designer-thane",
        MetaTitle: "T.V. Unit Designer in Thane | Ask Interior",
        MetaDes:  "Revamp your living space with a T.V. Unit Designer in Thane. Elevate your entertainment zone with bespoke designs, maximizing style and functionality.",
        CanonicalTag: "https://askinteriordesigner.in/tv-unit-designer-thane",
     
        ProejctDes: "As your showroom interior designer in Thane, we specialize in crafting dynamic and visually stunning spaces that showcase your products in their best light. Our skilled team of designers combines creativity with functionality to design showrooms that leave a lasting impression. Whether you're launching new collections or highlighting flagship products, we're dedicated to creating environments that elevate your brand and captivate your audience. Let us transform your showroom into a compelling space that reflects your brand identity and entices customers to explore and engage.",
        ProjectSelect: [
            {
                projectoption: "Tv Unit",
                category: "Tv Unit",
                categoryImage: [
                    {
                        Images: TvUnit01,
                    },
                ],
            },
            {
                projectoption: "Tv Unit",
                category: "Tv Unit",
                categoryImage: [
                    {
                        Images: TvUnit02,
                    },
                ],
            },
            {
                projectoption: "Tv Unit",
                category: "Tv Unit",
                categoryImage: [
                    {
                        Images: TvUnit03,
                    },
                ],
            },
        ]
    },
    {
        id: 5,
        ProjectImg: FalseCeilingMain,
        Projectvalue: "False Ceiling Designer in Thane",
        ProjectName: "False Ceiling Designer in Thane",
        ProjectName1: "False Ceiling",
        ProjectSlug: "/false-ceiling-designer-thane",
        MetaTitle: " False Ceiling Designer in Thane | Ask Interior",
        MetaDes:  "Transform your space with sophistication and style through a False Ceiling Designer in Thane. Elevate aesthetics, create ambiance, and redefine ceilings.",
        CanonicalTag: "https://askinteriordesigner.in/false-ceiling-designer-thane",
        ProejctDes: "A restaurant interior designer in Thane, Specializing in restaurant interiors, we blend aesthetics and functionality to create dining experiences that linger in memory. Our team of passionate designers understands the art of setting the perfect ambiance, from intimate bistros to chic fine dining. With an eye for detail, we infuse personality into every corner, ensuring your restaurant tells a story that complements your cuisine. Join us in crafting spaces that not only satisfy appetites but also elevate the dining journey. Welcome to a world where design meets delectable moments.",
        ProjectSelect: [
            {
                projectoption: "False Ceiling",
                category: "False Ceiling",
                categoryImage: [
                    {
                        Images: FalseCeiling01,
                    },
                ],

            },
            {
                projectoption: "False Ceiling",
                category: "False Ceiling",
                categoryImage: [
                    {
                        Images: FalseCeiling02,
                    },
                ],

            },
        ]
    },
    {
        id: 6,
        ProjectImg: diningroomMain,
        Projectvalue: "Best Dining Room Designer in Thane",
        ProjectName: "Best Dining Room Designer in Thane",
        ProjectName1: "Dining Room",
        ProjectSlug: "/best-dining-room-designer-thane",
        MetaTitle: "Best Dining Room Designer in Thane | Ask Interior",
        MetaDes:  "Elevate your dining experience with the Best Dining Room Designer in Thane. Immerse yourself in stylish and functional designs.",
        CanonicalTag: "https://askinteriordesigner.in/best-dining-room-designer-thane",
     
        ProejctDes: "As we are the best commercial interior designer in Thane, we specialize in curating personalized and elegant interiors that transform bungalows into dream homes. Our dedicated team of designers crafts spaces that harmonize with your lifestyle, ensuring every detail reflects your taste and preferences. From timeless aesthetics to modern comforts, we navigate the realms of design to create bungalow interiors that exude sophistication and warmth. Join us on a journey to redefine the essence of home, where every room is a reflection of your unique style and where luxury finds its true meaning. Welcome home.",
        ProjectSelect: [
            {
                projectoption: "Dining Room",
                category: "Dining Room",
                categoryImage: [
                    {
                        Images: diningroom01,
                    },
                ],

            },
        ]
    },
    {
        id: 7,
        ProjectImg: kitchenMain,
        Projectvalue: "Top Modular Kitchen Designer in Thane",
        ProjectName: "Top Modular Kitchen Designer in Thane",
        ProjectName1: "Kitchen",
        ProjectSlug: "/top-modular-kitchen-designer-thane",
        MetaTitle: " Top Modular Kitchen Designer in Thane | Ask Interior",
        MetaDes:  "Redesign your culinary space with the Top Modular Kitchen Designer in Thane. Immerse yourself in a blend of functionality and style, optimizing your kitchen.",
        CanonicalTag: "https://askinteriordesigner.in/top-modular-kitchen-designer-thane",
     
        ProejctDes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo vero iure quam? Ipsa rerum laboriosam pariatur, nostrum earum placeat quasi molestiae illo odio impedit soluta aspernatur necessitatibus praesentium hic sit?",
        ProjectSelect: [
            {
                projectoption: "Kitchen",
                category: "Kitchen",
                categoryImage: [
                    {
                        Images: kitchen01,
                    },
                ],
            },
            {
                projectoption: "Kitchen",
                category: "Kitchen",
                categoryImage: [
                    {
                        Images: kitchen02,
                    },
                ],
            },
            {
                projectoption: "Kitchen",
                category: "Kitchen",
                categoryImage: [
                    {
                        Images: kitchen03,
                    },
                ],
            },
            {
                projectoption: "Kitchen",
                category: "Kitchen",
                categoryImage: [
                    {
                        Images: kitchen04,
                    },
                ],
            },
            {
                projectoption: "Kitchen",
                category: "Kitchen",
                categoryImage: [
                    {
                        Images: kitchen05,
                    },
                ],
            },
        ]
    },
    {
        id: 8,
        
        ProjectImg: BedRoomMain,
        Projectvalue: "Bedroom Interior Designer",
        ProjectName: "Bedroom Interior Designer in Thane ",
        ProjectName1: "Room",
        ProjectSlug: "/bedroom-interior-designer-thane",
        MetaTitle: " Bedroom Interior Designer in Thane  | Ask Interior",
        MetaDes:  "Elevate your living experience with our professional Bedroom Interior Designer services in Thane. Discover innovative designs and personalized solutions.",
        CanonicalTag: "https://askinteriordesigner.in/bedroom-interior-designer-thane",
  
        ProejctDes: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo vero iure quam? Ipsa rerum laboriosam pariatur, nostrum earum placeat quasi molestiae illo odio impedit soluta aspernatur necessitatibus praesentium hic sit?",
        ProjectSelect: [
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom01,
                    },
                ],
            },
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom02,
                    },
                ],
            },
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom03,
                    },
                ],
            },
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom04,
                    },
                ],
            },
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom05,
                    },
                ],
            },
            {
                projectoption: "BedRoom",
                category: "BedRoom",
                categoryImage: [
                    {
                        Images: BedRoom06,
                    },
                ],
            },
            {
                projectoption: "Wardrobe",
                category: "Wardrobe",
                categoryImage: [
                    {
                        Images: Wardrobe1,
                    },
                ],
            },
            {
                projectoption: "Wardrobe",
                category: "Wardrobe",
                categoryImage: [
                    {
                        Images: Wardrobe2,
                    },
                ],
            },
            {
                projectoption: "Wardrobe",
                category: "Wardrobe",
                categoryImage: [
                    {
                        Images: Wardrobe3,
                    },
                ],
            },
            {
                projectoption: "Wardrobe",
                category: "Wardrobe",
                categoryImage: [
                    {
                        Images: Wardrobe4,
                    },
                ],
            },
            {
                projectoption: "Wardrobe",
                category: "Wardrobe",
                categoryImage: [
                    {
                        Images: Wardrobe5,
                    },
                ],
            },
            {
                projectoption: "BathRoom",
                category: "BathRoom",
                categoryImage: [
                    {
                        Images: BathRoom01,
                    },
                ],
            },
            {
                projectoption: "BathRoom",
                category: "BathRoom",
                categoryImage: [
                    {
                        Images: BathRoom02,
                    },
                ],
            },
            {
                projectoption: "BathRoom",
                category: "BathRoom",
                categoryImage: [
                    {
                        Images: BathRoom03,
                    },
                ],
            },
            {
                projectoption: "BathRoom",
                category: "BathRoom",
                categoryImage: [
                    {
                        Images: BathRoom04,
                    },
                ],
            },
        ]
    },
  
]

export default Projectdata;
