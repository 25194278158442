import React, { useLayoutEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Projectdata from '../Component/Projectdata';
import Carscard from '../Component/Carscard';
import Footer from '../Component/Footer';
const ProjectInfo = () => {

    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });


    const search = useLocation();
    const Path = search.pathname;

    const [edata, setEdata] = useState(Projectdata)

    const EventFdata = edata.find(e => e.ProjectSlug == Path)

    const Fdata = EventFdata.ProjectSelect


    return (
        <>
            {/* -------------------------- Section 1 -------------------------- */}
            <div className='project-Section-1'>
                {/* <Header /> */}
                <div className='project-Section-Banner'>
                    <div className='project-banner-text'>
                        <h1>  {EventFdata.ProjectName}</h1>
                        <div className='project-banner-text-page'>
                            <Link to="/">
                                Home
                            </Link>
                            <h2>
                                /
                            </h2>
                            <h2>
                                {EventFdata.ProjectName1}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            {/* ------------------------------ Section 2 -------------------------------- */}

            <section className='Magazine-Section-02'>
                <div className='container'>
                    <div className='Magazine-Section-02-head'>
                        {/* <div className='Magazine-Section-02-headCon'>
                            <h2> <span>{EventFdata.ProjectName}</span> </h2>
                           
                        </div> */}
                    </div>
                </div>
            </section>

            {/* ------------------------------ Section 3 -------------------------------- */}


            <section className='Cars-Section-2'>
                <div className='container'>
                    <div className='Cars-Section-Show'>
                        <div className='row'>
                            {
                                Fdata.map((element, index) => {
                                    // const {}
                                    return (
                                        <>
                                            <Carscard {...element} key={index} categoryImage={element.categoryImage} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default ProjectInfo