import React from 'react'
import {Link} from "react-router-dom";
import Footer from "../Component/Footer";
const Errorpage = () => {
    return (
        <>
          <div className="project-Section-1">
            {/* <Header /> */}
            <div className="project-Section-Banner">
              <div className="project-banner-text">
                <h1>Page Not Found</h1>
                <div className="project-banner-text-page">
                  <Link to="/">Back to Home</Link>
               
                </div>
              </div>
            </div>
          </div>
          {/* ========== Section2 =========== */}
          <section className="Magazine-Section-02">
            <div className="container">
              <div className="Magazine-Section-02-head">
                <div className="Magazine-Section-02-headCon">
                  <h2>404-Error</h2>

                  <p>Back to <Link to="/"><span>Home</span></Link>
                                      </p>
                </div>
              </div>
            </div>
          </section>
      
          <Footer />
        </>
      );
}

export default Errorpage