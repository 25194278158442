import React, { useEffect, useRef, useState } from "react";
import "../CSS/Homepage.css";
// Swiper
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Header from "../Component/Header";
import Fade from "react-reveal/Fade";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import ReviewsSwaiper from "../Component/Reviews/ReviewsSwaiper";
import HomeContact from "../Component/Contact/HomeContact";
import { MetaTags } from 'react-meta-tags';

const HomePage = () => {
  return (
    <>
      <MetaTags>
        <title>Top Interior Designers in Thane | Ask Interior</title>
        <meta title="Top Interior Designers in Thane | Ask Interior" />
        <meta name="description" content="Experience unparalleled design expertise with the Top Interior Designers in Thane. Transform your home or office into a masterpiece of style and functionality." />
        <meta name="keywords" content="Top Interior Designers in Thane,Interior Designers near Thane,Best Interior Designer in Thane,Residential Interior Designers in Thane,Commercial Interior Designers in Thane,Top Commercial Interior Designers at Thane,Showroom Interior Designer in Thane,Restaurant Interior Designers in Thane,Best Commercial Interior Designers in Thane,Interior Service in Thane ,Famous Interior Designers in Thane" />
        <link rel="canonical" href="https://askinteriordesigner.in/top-interior-designers-thane" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Interior Designers in Thane | Ask Interior" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://askinteriordesigner.in/" />
        <meta property="og:description" content="Experience unparalleled design expertise with the Top Interior Designers in Thane. Transform your home or office into a masterpiece of style and functionality." />
        <meta property="og:image" content="" />
      </MetaTags>

      {/* =========== Banner Sedction ====== */}
      <div className="Home-Section01">
        {/* <Header /> */}
        <div className="Home-Section-Banner">
          <div className="container">
            <Swiper
              spaceBetween={30}
              centeredSlides={true} 
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              // modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="Home-Section-Banner-Swiper">
                  <div className="Home-Section-Banner-Swiper-con">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="Home-Section-Banner-Swiper-text-con01">
                          <Fade top cascade>
                            <div className="Home-Section-Banner-Swiper-text">
                              <h1>Top Interior Designers in Thane</h1>
                              <p>
                                Elevate Your Space with our top interior designer in Thane. Immerse yourself in a world of timeless design where innovation meets sophistication. Our passion is crafting bespoke interiors that redefine living and working spaces.
                              </p>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Home-Section-Banner-Swiper">
                  <div className="Home-Section-Banner-Swiper-con">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="Home-Section-Banner-Swiper-text-con02">
                          <div className="Home-Section-Banner-Swiper-text">
                            <h2>Style Redefined, Spaces Reimagined.</h2>
                            <p>
                              From concept to creation, let us transform your vision into a masterpiece. Discover the art of interior design – where every detail tells a story.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="Home-Section-Banner-Swiper">
                  <div className="Home-Section-Banner-Swiper-con">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="Home-Section-Banner-Swiper-text-con03">
                          <div className="Home-Section-Banner-Swiper-text">
                            <h2>Inspiring Environments, Exceptional Designs.</h2>
                            <p>
                              Welcome to the top interior designer in Thane, where inspiration meets execution. Your dream space begins here.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      {/* ========= Section2 ======= */}
      <section className="about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="exp-img">
                <div
                  className="img bg-img imago animated"
                  data-background="../Images/Home/Home About.jpg">
                  <div className="years custom-font">
                    <div className="since custom-font">
                      <span>Since 2016</span>
                    </div>
                    <h2>7</h2>
                    <h5>Years Experience</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 valign">
              <div className="exp-content">
                <h6 className="sub-title">About Us</h6>
                <h2 className="mb-20 playfont">
                  Best Designers Architectures for You.
                </h2>
                <p>
                  Welcome to ASK INTERIORS, the top interior designer in Thane, where we redefine spaces with a touch of elegance and innovation. As seasoned interior designers, we embark on a journey to transform your dreams into captivating realities.
                </p>
                <p>
                  Our commitment lies in creating bespoke designs that not only reflect your individuality but also enhance the functionality of every space. At ASK INTERIORS, a top interior designer in Thane, we believe in the power of design to elevate the way you live, work, and experience your surroundings. Step into a world of curated aesthetics and unparalleled craftsmanship with us.
                </p>
                <div className="numbers mt-50">
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <div className="item">
                        <h3>
                          <span className="nbr custom-font">
                            <CountUp end={567} duration={5} />
                          </span>
                        </h3>
                        <h6>Projects Completed</h6>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="item">
                        <h3>
                          <span className="nbr custom-font">
                            <CountUp end={567} duration={5} />
                          </span>
                        </h3>
                        <h6>Satisfied Clients</h6>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="item">
                        <h3>
                          <span className="nbr custom-font">
                            <CountUp end={17} duration={5} />
                          </span>
                        </h3>
                        <h6>Awards Won</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============= Section3 =========== */}
      <section className="services section-padding bg-gray">
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font testi-text">
                  Best Features
                </h6>
                <h4
                  className="playfont testi-text" style={{ color: '#000' }}>
                  Our Services
                </h4>
              </div>
            </div>
          </div>
          <div
            className="row bord-box bg-img hsIthree row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1">
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">01</h2>
                <h6 className="mb-20">Complete interior work</h6>
                <p>
                  Your trusted partner for complete interior solutions. With a passion for precision and an eye for detail, we specialize in turning spaces into stunning reflections of your unique style.
                </p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">02</h2>
                <h6 className="mb-20">Wood work</h6>
                <p>
                  ASK INTERIORS, a top interior designer in Thane, where craftsmanship meets creativity in the world of woodwork.
                </p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">03</h2>
                <h6 className="mb-20">Civil work</h6>
                <p>
                  ASK INTERIORS, where excellence in civil work converges with innovative interior design solutions.
                </p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">04</h2>
                <h6 className="mb-20">Pop work</h6>
                <p>ASK INTERIORS, where precision and creativity unite in the realm of POP work. As specialists in interior design, we take pride in our expertise in transforming spaces with exquisite POP craftsmanship.</p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">05</h2>
                <h6 className="mb-20">Plumbing work</h6>
                <p> As a cornerstone of our comprehensive interior design services, we take pride in delivering top-notch plumbing work to ensure functionality and comfort in every space.</p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">06</h2>
                <h6 className="mb-20">Furnishing work</h6>
                <p> As a leading interior design company, we specialize in curating exceptional furnishing solutions that transcend the ordinary. </p>
                <div className="mt-30"></div>
                <Link to="/Service" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
            <div className="col Servicecol">
              <div className="item-bx">
                <h2 className="custom-font numb">07</h2>
                <h6 className="mb-20">Electric work</h6>
                <p>ASK INTERIORS, where innovation meets illumination in the realm of electric work within interior design. </p>
                <div className="mt-30"></div>
                <Link to="/Project" className="more custom-font mt-30">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ============ section4 ========= */}
      <section className="skills-circle section-padding bg-img parallaxie">
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font">Best Skills</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="skill">
                  <span className="custom-font">90%</span>
                  <h6>ARCHITECTURE</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item text-center">
                <div className="skill">
                  <span className="custom-font">75%</span>
                  <h6>INTERIOR DESIGN</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="skill">
                  <span className="custom-font">88%</span>
                  <h6>INOVATION</h6>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="skill">
                  <span className="custom-font">83%</span>
                  <h6>CONTEMPORARY IDEAS</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ========== Section5 ========= */}
      <ReviewsSwaiper />
      <HomeContact />
      <Footer />
    </>
  );
};

export default HomePage;
