import React from 'react'
import "../../CSS/ReviewsSwaiper.css";
import ReviewsComponent from '../../Component/Reviews/ReviewsComponent'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import quote from "../../Img/quote.svg";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import required modules

import { AiFillStar } from 'react-icons/ai';
import { BsFillEmojiSmileFill } from 'react-icons/bs';

// import Footer from '../Components/Footer';



const ReviewsSwaiper = () => {
    return (
        <>
            {/* ========== Section5 ========= */}
            <section className="testimonials grid section-padding">
                <div className="container">
                    <div className="section-head text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-8 col-sm-10">
                                <h6 className="custom-font">Testimonials</h6>
                                <h4 className="playfont testi-text">What People Says?</h4>
                            </div>
                        </div>

                        <div className="Home-Section-5-Testimonial">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={10}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 40,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                        spaceBetween: 50,
                                    },
                                }}
                                modules={[Autoplay]}
                                className="mySwiper"
                            >
                                <SwiperSlide>
                                    <div className="item slick-slide">
                                        <span className="quote-icon">
                                            <img src={quote} alt="" />
                                        </span>
                                        <div className="cont">
                                            <p className="playfont">
                                                They are professional punctual. They do their work with lots of effort, answer every question with politeness excellent Knowledge of interior design if you people are looking for  interior designer  I would suggest you to take them as 1st priority                      </p>
                                        </div>
                                        <div className="info">
                                            {/* <div className="author">
                        <img src={client} alt="" />
                      </div> */}
                                            <h6>
                                                pratiksha Shetty
                                            </h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item wow fadeInUp slick-slide">
                                        <span className="quote-icon">
                                            <img src={quote} alt="" />
                                        </span>
                                        <div className="cont">
                                            <p className="playfont">
                                                Had an amazing experience with my interior designer. Their creativity, professionalism, and attention to detail were outstanding. They listened to my ideas and brought them to life while also suggesting innovative concepts. The end result was a stunning and personalized space that I absolutely love. I highly recommend their services! THANKS Ask interior contractor team 👍
                                            </p>
                                        </div>
                                        <div className="info">
                                            {/* <div className="author">
                        <img src={client} alt="" />
                      </div> */}
                                            <h6>
                                                Ayush Choubey
                                            </h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="item wow slick-slide">
                                        <span className="quote-icon">
                                            <img src={quote} alt="" />
                                        </span>
                                        <div className="cont">
                                            <p className="playfont">
                                                Team Ask interior is very Professional, Systematic and all the members are learned and experienced, so they offered us a one stop solution from design to implementation and also offered  the entire services in good price. Delivered on time and were very patient and responsive throughout the project duration.
                                            </p>
                                        </div>
                                        <div className="info">
                                            {/* <div className="author">
                        <img src={client} alt="" />
                      </div> */}
                                            <h6>
                                                Anil Raj
                                            </h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="item wow fadeInUp slick-slide"
                                        data-wow-delay=".3s"
                                        data-slick-index={3}
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.3s",
                                            animationName: "fadeInUp",
                                            width: 515,
                                        }}
                                        aria-hidden="true"
                                        tabIndex={-1}
                                    >
                                        <span className="quote-icon">
                                            <img src={quote} alt="" />
                                        </span>
                                        <div className="cont">
                                            <p className="playfont">
                                                The team at this interior design company demonstrated an exceptional understanding of my vision and translated it into a space that exceeded my expectations. They carefully listened to my ideas and preferences, seamlessly incorporating them into a cohesive and stylish design. Their ability to blend functionality with aesthetics resulted in a space that perfectly suits my lifestyle while reflecting my personal taste.
                                            </p>
                                        </div>
                                        <div className="info">
                                            {/* <div className="author">
                        <img src={client} alt="" />
                      </div> */}
                                            <h6>
                                                Rahul Dubey
                                            </h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div
                                        className="item wow fadeInUp slick-slide"
                                        data-wow-delay=".3s"
                                        data-slick-index={3}
                                        style={{
                                            visibility: "visible",
                                            animationDelay: "0.3s",
                                            animationName: "fadeInUp",
                                            width: 515,
                                        }}
                                        aria-hidden="true"
                                        tabIndex={-1}
                                    >
                                        <span className="quote-icon">
                                            <img src={quote} alt="" />
                                        </span>
                                        <div className="cont">
                                            <p className="playfont">
                                                Honestly one of the best interior designing company. Easy to reach, accessible and great hospitality.
                                            </p>
                                        </div>
                                        <div className="info">
                                            {/* <div className="author">
                        <img src={client} alt="" />
                      </div> */}
                                            <h6>
                                                lovkush rajbhar
                                            </h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReviewsSwaiper;